'use client';
import * as React from 'react';
import AmplifyUtils from '../src/utils/AmplifyUtils';
import { shallow } from 'zustand/shallow';
import { useRouter, useSearchParams } from 'next/navigation';
import { useCognitoUser, UserState } from './dashboard/hooks/useCognitoUser';
import { Spinner } from 'flowbite-react';
import useDashboardStore from './dashboard/stores/useDashboardStore';
import useOnboardingFormStore from './dashboard/stores/useOnboardingFormStore';
import useSubscriptionStore from './dashboard/stores/useSubscriptionStore';
import { addCustomAnalyticsUserAttributes } from '../src/utils/AnalyticsUtils';
import { UserAttributesType } from './onboarding/utils/types';
import { SessionStorageItemType } from './dashboard/types';

import {userSignedIn as analyticsUserSignedIn, identifyUser as analyticsIdentifyUser} from '../src/utils/AnalyticsUtils';
import { defaultPriceCurrency } from './subscription/utils/types';
import useSpaceStore from './dashboard/stores/useSpaceStore';


AmplifyUtils.configureAmplify();

export default function HomePage() {
    const router = useRouter();
    const [loading, setLoading] = React.useState(false);
    const [onboardingCheck, setOnboardingCheck] = React.useState<boolean>(false);
    const [userAttributes, state, user] = useCognitoUser();
    const [availableProducts, fetchProducts, isProductsFetchingEnded, toggleSideBarFromStorage] = useDashboardStore((state) => [state.availableProducts, state.fetchProducts, state.isProductsFetchingEnded, state.toggleSideBarFromStorage], shallow);
    const [stateOnboarding, setCognitoUser, getOrCreateUserWithLimits, createUser, userOnboarding, isOnboardingFormSubmited, isUIStepsSubmited, isFirstUIStepSubmited] = useOnboardingFormStore(
        (state) => [state, state.setCognitoUser, state.getOrCreateUserWithLimits, state.createUser, state.user, state.isOnboardingFormSubmited, state.isUIStepsSubmited, state.isFirstUIStepSubmited],
        shallow,
    );
    const [fetchStripeCustomer, stripeCustomer, subscriptionProduct, setLimitation, fetchStripeProducts, setCustomerSubscriptionFetched, isCustomerSubscriptionFetched] = useSubscriptionStore((state) => [state.fetchStripeCustomer, state.stripeCustomer, state.subscriptionProduct, state.setLimitation, state.fetchProducts, state.setCustomerSubscriptionFetched, state.isCustomerSubscriptionFetched], shallow);
    const params = useSearchParams();
    const [createSpaceIfNotExist] = useSpaceStore((state) => [state.createSpaceIfNotExist], shallow);
    
    React.useEffect(() => {
      const emailParamVal = params?.get('email')
      if (emailParamVal)
      {
          analyticsIdentifyUser(emailParamVal)
      } 

   }, [params, router]);

    React.useEffect(() => {
      if (state === UserState.Authenticated && user) {
        if (userAttributes?.fullName === 'First Name Last Name') {
          router.push('/auth/login');
        } else {
          analyticsUserSignedIn()
          setCognitoUser({ user });
          getOrCreateUserWithLimits({ user });
          createSpaceIfNotExist({ user }).then(() => {
            fetchProducts(true);
          });
          if (sessionStorage.getItem(SessionStorageItemType.SIDEBAR_HIDDEN_STATE)) {
              toggleSideBarFromStorage({ sideBarCurrentState: sessionStorage.getItem(SessionStorageItemType.SIDEBAR_HIDDEN_STATE) });
          }
        }
      } else if (state === UserState.NotAuthenticated) {
          router.push('/auth/login');
      }
    }, [getOrCreateUserWithLimits, user, state, createUser, setCognitoUser, router, fetchProducts, toggleSideBarFromStorage]);

    React.useEffect(() => {
      if (Object.keys(userOnboarding).length && userAttributes && user) {
        if (userOnboarding.stripeId) {
            fetchStripeCustomer({ stripeId: userOnboarding.stripeId, email: userAttributes.email, user, userOnboarding });
            fetchStripeProducts(userOnboarding.location && userOnboarding.location.currency ? userOnboarding.location.currency.toLowerCase() : defaultPriceCurrency);
        } else {
            setCustomerSubscriptionFetched();
            addCustomAnalyticsUserAttributes(UserAttributesType.SUBSCRIPTION, { 'Plan Type': 'Free' });
            addCustomAnalyticsUserAttributes(UserAttributesType.USER_DATA, { 'User Data': JSON.stringify(userOnboarding) })
        }
      }
    },[fetchStripeCustomer, userOnboarding, fetchStripeProducts, setCustomerSubscriptionFetched, userAttributes, user])

    React.useEffect(() => {
      if (user && Object.keys(userOnboarding).length && userOnboarding.limitation && isCustomerSubscriptionFetched) {
        setLimitation({ user, limitation: userOnboarding.limitation })
      }
    },[setLimitation, user, userOnboarding, isCustomerSubscriptionFetched])

    React.useEffect(() => {
      if (Object.keys(userOnboarding).length) {
        addCustomAnalyticsUserAttributes(UserAttributesType.USER_DATA, { 'User Data': JSON.stringify(userOnboarding) })
        setOnboardingCheck(true);
        if (userOnboarding.isSubscriptionCanceled) {
          router.push('/dashboard?type=cancel');
        } else {
          if (isProductsFetchingEnded) {
            router.push('/dashboard?view=home');
          }
        }
      }
    }, [isUIStepsSubmited, isOnboardingFormSubmited, router, userOnboarding, isFirstUIStepSubmited, availableProducts, isProductsFetchingEnded]);

    return (
        <>
                <div className="mx-auto mt-28 text-center">
                    <Spinner />
                </div>
        </>
    );
}
