import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { CognitoUser } from '@aws-amplify/auth';
import dataOperations from '../../../lib/UserSpace';

export interface SpaceStoreState {
    activeSpace: string | null;
    rootFolderGuid: string;
    isCreatingSpace: boolean;

    getUserActiveSpace: (props: { user: CognitoUser }) => Promise<void>;
    createSpaceIfNotExist: (props: { user: CognitoUser }) => Promise<void>;

    reset: () => void;
}

const defaultValues = {
    activeSpace: '',
    rootFolderGuid: '',
    isCreatingSpace: false,
};

const useSpaceStore = create<SpaceStoreState>()(
    devtools(
        (set, get) => ({
            ...defaultValues,
            reset: () => {
                set({ ...defaultValues }, false, 'useSpaceStore/reset');
            },
            getUserActiveSpace: async (props: { user: CognitoUser }) => {
                const space = await dataOperations.getActiveSpace(props.user);
                set({ activeSpace: space }, false, 'useSpaceStore/getUserActiveSpace');
            },
            createSpaceIfNotExist: async (props: { user: CognitoUser }) => {
                if (get().rootFolderGuid) return;

                set(() => ({ isCreatingSpace: true }), false, 'useSpaceStore/createSpace start');
                try {
                    let folderGuid = await dataOperations.getActiveSpaceRootFolder(props.user);
                    if (!folderGuid) {
                        await dataOperations.createActiveSpaceAndFolder(props.user);
                        folderGuid = await dataOperations.getActiveSpaceRootFolder(props.user);
                        // add retry?
                    }

                    if (folderGuid) {
                        set(
                            (prev) => ({
                                isCreatingSpace: false,
                                folderGuid,
                            }),
                            false,
                            'useSpaceStore/createSpace added',
                        );
                    }
                } catch (error) {
                    console.log('Failed to create space', error);
                } finally {
                    set({ isCreatingSpace: false }, false, 'useSpaceStore/createSpace finish');
                }
            },
        }),
        {
            name: 'space-store',
        },
    ),
);

export default useSpaceStore;
